@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();

$primary: hsl(177, 100%, 34%);
$primary-dark: desaturate(darken($primary, 6%), 64%);
$complement-one: hsl(296, 38%, 46%);
$complement-one-dark: desaturate(darken($complement-one, 22%), 16%);
$complement-two: hsl(28, 100%, 60%);
$info: $primary;
$warning: $complement-two;
$danger: hsl(6, 100%, 60%);
$success: hsl(140, 68%, 60%);

$theme-colors: (
	'complement-one': $complement-one,
	'complement-two': $complement-two,
);

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
	0: 0,
	1q: (
		$spacer * 0.25,
	),
	2q: (
		$spacer * 0.5,
	),
	3q: (
		$spacer * 0.75,
	),
	1: (
		$spacer * 1,
	),
	5q: (
		$spacer * 1.25,
	),
	2: (
		$spacer * 2,
	),
	3: (
		$spacer * 3.25,
	),
	4: (
		$spacer * 4.81,
	),
	5: (
		$spacer * 6.76,
	),
	6: (
		$spacer * 9.2,
	),
	7: (
		$spacer * 12.25,
	),
	8: (
		$spacer * 16.06,
	),
);
$border-width: 1px;

$body-bg: $white;
$body-color: $gray-800;

$link-color: $primary;
$link-decoration: none;
$link-hover-color: $primary-dark;
$link-hover-decoration: none;

// $container-max-widths: (
//   sm: 560px,
//   md: 740px,
//   lg: 860px,
//   xl: 940px
// );

$grid-columns: 12;
$grid-gutter-width: 32px;

$line-height-lg: 2;
$line-height-sm: 1.6;

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 0.4rem;
$border-radius-lg: 0.8rem;
$border-radius-sm: 0.3rem;

$font-family-sans-serif: 'Raleway', 'Arial', sans-serif;
// $font-family-serif: 'Noto Serif', Georgia, serif;
$font-family-serif: 'Raleway', 'Arial', sans-serif;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xl: 1.563rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.9rem;
$font-size-xs: 0.64rem;

$small-font-size: 90%;

$font-size-scale-sans-serif: 1;
$font-size-base-sans-serif: $font-size-base * $font-size-scale-sans-serif;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.8;

$h1-font-size: 3.052rem * $font-size-scale-sans-serif;
$h2-font-size: 2.441rem * $font-size-scale-sans-serif;
$h3-font-size: 1.953rem * $font-size-scale-sans-serif;
$h4-font-size: 1.563rem * $font-size-scale-sans-serif;
$h5-font-size: 1.25rem * $font-size-scale-sans-serif;
$h6-font-size: 1rem * $font-size-scale-sans-serif;

$headings-margin-bottom: $spacer;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1.4;
$headings-color: inherit;

$input-placeholder-color: $gray-400;

$custom-control-gutter: 1.75rem;
$custom-control-spacer-x: 1.25rem;
$custom-control-indicator-size: 1.25rem;

@import 'bootstrap/scss/variables';
