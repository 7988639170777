.last-child-mb-0 {
	*:last-child {
		margin-bottom: 0 !important;
	}
}

.break-word {
	overflow-wrap: break-word;
	hyphens: auto;
}

.font-family-sans-serif {
	font-family: $font-family-sans-serif;
	font-size: $font-size-base-sans-serif;
}

.font-family-serif {
	font-family: $font-family-serif;
}

.font-family-monospace {
	font-family: $font-family-monospace;
}

.font-size-base {
	font-size: $font-size-base;
}

.font-size-inherit-important {
	font-size: inherit !important;
}

.font-size-sm {
	font-size: $font-size-sm;
}

.font-size-sm-important {
	font-size: $font-size-sm !important;
}

.font-size-lg {
	font-size: $font-size-lg;
}

.font-size-xl {
	font-size: $font-size-xl;
}

.blockquote-border {
	border-left: 6px solid $gray-200;
}

.page-break-unit {
	page-break-after: auto;
	page-break-before: auto;
	page-break-inside: avoid;
}

.hide-scrollbars {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

img.disabled {
	opacity: $btn-disabled-opacity;
	filter: grayscale(100%);
}

label.required-label {
	position: relative;
	&:after {
		content: '*';
		position: absolute;
		color: $complement-two;
		font-size: 2 * $font-size-base-sans-serif;
		line-height: 1;
		margin-left: 2px;
	}
}
