.listing-textblob {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: inherit;
		font-size: inherit;
		font-family: $font-family-serif;
		margin-bottom: 0;
	}

	.textblob-section-title {
		font-family: $font-family-sans-serif;
		font-size: $h5-font-size;
		font-weight: bold;
		margin-top: map-get($spacers, 3);
		margin-bottom: map-get($spacers, 1);
		padding: 0;
		&:first-of-type {
			margin-top: 0;
		}
	}

	blockquote {
		margin: 0 0 1rem 0;
		padding-left: 0.75rem;
		border-left: 0.25rem solid rgba(0, 0, 0, 0.175);
	}

	.trix-button--icon-heading-1 {
		display: none;

		& + button.trix-button {
			border-left: none;
		}
	}
}
