blockquote.jumboquote {
	.quote {
		font-family: 'Spectral', $font-family-serif;
		font-weight: 300;
		font-style: italic;
		font-size: $h5-font-size;
		&:before {
			color: $gray-400;
			content: open-quote;
			font-size: 4em;
			line-height: 0;
			margin-right: 0.26em;
			vertical-align: -0.4em;
		}
		&:after {
			color: $gray-400;
			content: close-quote;
			font-size: 4em;
			line-height: 0;
			margin-left: 0.2em;
			vertical-align: -0.62em;
		}
	}

	cite {
		font-style: normal;
	}

	.avatar {
		border-radius: 50%;
		overflow: hidden;
		max-width: 100%;
	}
}
