// Base variables
@import 'base';

// Dependencies
@import 'bootstrap/scss/bootstrap';
@import 'integrations/flatpickr';
@import 'integrations/fontawesome';

// Components
@import 'components/admin/workflow';
@import 'components/convenience';
@import 'components/jumboquote';
@import 'components/jumbotron';
@import 'components/listing-textblob';
@import 'components/tufte';

html {
	font-size: 16px;
	font-variant-ligatures: common-ligatures contextual;
	font-feature-settings: 'liga', 'clig', 'calt', 'kern';
	font-kerning: normal;
	scroll-behavior: smooth;
}

html.app-layout {
	min-height: 100%;
	height: 100%;

	body,
	body > div.wrapper {
		min-height: 100%;
		height: 100%;
	}

	header,
	footer {
		flex-shrink: 0;
	}

	main {
		flex: 1 0 auto;
	}
}

html.embedded-layout {
	body,
	body > div.wrapper,
	main {
		// overflow-y: auto;
	}
}

html.pdf-export-layout {
	-webkit-print-color-adjust: exact;

	body {
		margin: 2px;
		padding: 2px;
	}
}

header {
	transition: 0.33s opacity ease-in-out;
	&:hover {
		opacity: 1;
	}
	nav {
		// border-bottom: 1px solid $white;
	}

	a {
		color: $primary;
		// font-family: $font-family-serif;
	}

	h1 {
		font-weight: 100;
	}

	nav.navbar .nav-item {
		& > .nav-link {
			padding: 0.5rem;
		}
		&:first-child > .nav-link {
			padding-left: 0;
		}
		&:last-child > .nav-link {
			padding-right: 0;
		}

		.dropdown-toggle::after {
			margin-left: 0.35em;
			vertical-align: 0.15em;
		}

		.dropdown-menu .nav-link:not(.active) {
			color: $navbar-light-color;
		}
	}
}

footer {
	border-top: 1px solid $gray-200;
	font-family: $font-family-serif;
	color: $gray-700;
}

small {
	line-height: $line-height-sm;
}

main.social {
	min-height: 640px;
	width: 1200px;

	.listing {
		h1 {
			font-size: 4.768rem;
			font-weight: 300;
			line-height: 1.2;
			strong {
				font-weight: 600;
			}
		}

		.item-meta {
			opacity: 0.75;
			margin-bottom: 0;
			font-size: $h3-font-size;
			font-weight: 100;
			color: rgba(255, 255, 255, 1);
			li.list-inline-item {
				display: block;
				em {
					color: rgba(255, 255, 255, 1);
					font-weight: 400;
				}
				i {
					color: rgba(255, 255, 255, 0.75);
					margin-right: 1.5rem !important;
				}
				&:after {
					content: none;
				}
			}
		}

		.description {
			font-size: $h3-font-size;
			line-height: $line-height-sm;
			font-weight: 400;
			color: #fff;
		}
	}
}

main.tkn-assignment-spec {
	font-family: 'Times New Roman';
	font-size: 17.2px;

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Times New Roman';
	}

	.spec-section {
		border-top: 2px solid $gray-600;
	}

	.cell {
		padding: 6px 12px 6px;
		border-bottom: 1px solid $gray-500;
		border-left: 1px solid $gray-500;

		&:last-child {
			border-right: 1px solid $gray-500;
		}
	}

	.header {
		background-color: $gray-100;
		font-weight: bold;
	}

	.label,
	.value {
	}

	.label {
	}

	.label.label-required {
		position: relative;
		padding-left: 24px;

		&::before {
			content: '*';
			color: $danger;
			font-size: 24px;
			line-height: 1;
			position: absolute;
			left: 9px;
			top: 9px;
		}
	}

	.value {
		background-color: rgba(220, 230, 241, 0.666);
	}

	.value-longform {
		min-height: 102px;
	}
}

section.listing-title {
	.social {
		font-size: 1.5rem;
		line-height: 1;

		li.list-inline-item {
			margin-right: 10px;
		}

		a {
			color: $complement-one;

			&:hover {
				color: $complement-one-dark;
			}
		}
	}
}

section.listing-apply {
	.custom-control-label::before,
	.custom-control-label::after {
		top: 0.375rem;
	}
}

section.admin-subtitle {
	background: #f7f7f7;
	border-bottom: 1px solid $nav-tabs-border-color;
	border-top: 1px solid $nav-tabs-border-color;
}

.alert + section.admin-subtitle {
	border-top: 0px;
}

// TODO: test this out
@media (-webkit-max-device-pixel-ratio: 1.3), (max-resolution: 124.8dpi) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.font-family-sans-serif {
		letter-spacing: 0.6px;
	}
}

.tufte {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		@include tufte-anchor-underline($body-bg, $link-color, $link-hover-color);
	}
}

.item-meta {
	color: $gray-600;
	margin-bottom: 0;

	li.list-inline-item {
		margin-right: map-get($spacers, 3q);
		i {
			color: $primary-dark;
		}
		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '|';
			color: $gray-300;
			font-family: $font-family-serif;
			font-weight: 100;
			margin-left: map-get($spacers, 3q);
		}
		&:last-child:after {
			content: none;
		}
	}
}

.form-group trix-editor.form-control {
	min-height: 8rem;
	height: auto;
}
